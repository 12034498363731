import React, { useState } from 'react';
import "../css/Hadder.css"
import "../css/style.css"
import logo from "../Asset/logo.png"
import { FaRegClock } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import Menu from "../css/Menu.css"
import MailPop from './MailPop';
const Hadder = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>


<header className="header-area header-three nevtwo">
  <div className="header-top second-header d-none d-md-block">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-10 col-md-10 d-none d-lg-block">
          <div className="header-cta">
            <ul>
              <li>
                <i className="far fa-clock" />
                <span>Mon - Fri: 9:00 - 19:00/ Closed on Weekends</span>
              </li>
              <li>
                <i className="far fa-mobile" />
                <strong>+91 705 210-1786</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 d-none d-lg-block text-right">
          <div className="header-social">
            <span>
              <a href="#" title="Facebook">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#" title="LinkedIn">
                <i className="fab fa-instagram" />
              </a>
              <a href="#" title="Twitter">
                <i className="fab fa-twitter" />
              </a>
              <a href="#" title="Twitter">
                <i className="fab fa-youtube" />
              </a>
            </span>
            {/*  /social media icon redux */}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="header-sticky" className="menu-area">
    <div className="container">
      <div className="second-menu">
        <div className="row align-items-center">
          <div className="col-xl-2 col-lg-2">
            <div className="logo">
              <a href="index.html">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8">
            <div className="main-menu text-center">
              <div className="mean-push" />
              <nav id="mobile-menu" style={{ display: "none" }}>
                <ul>
                  <li className="has-sub">
                    <a href="index.html">Home</a>
                    <ul>
                      <li>
                        <a href="index.html">Home Page 01</a>
                      </li>
                      <li>
                        <a href="index-2.html">Home Page 02</a>
                      </li>
                      <li>
                        <a href="index-3.html">Home Page Side Menu</a>
                      </li>
                      <li>
                        <a href="index-4.html">Home Page Full Menu</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li className="has-sub">
                    <a href="room.html">our rooms</a>
                    <ul>
                      <li>
                        {" "}
                        <a href="room.html">Our Rooms</a>
                      </li>
                      <li>
                        {" "}
                        <a href="single-rooms.html">Rooms Details</a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-sub">
                    <a href="services.html">Facilities</a>
                    <ul>
                      <li>
                        {" "}
                        <a href="services.html">Services</a>
                      </li>
                      <li>
                        {" "}
                        <a href="single-service.html">Services Details</a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-sub">
                    <a href="#">Pages</a>
                    <ul>
                      <li>
                        <a href="projects.html">Gallery</a>
                      </li>
                      <li>
                        <a href="faq.html">Faq</a>
                      </li>
                      <li>
                        <a href="team.html">Team</a>
                      </li>
                      <li>
                        <a href="team-single.html">Team Details</a>
                      </li>
                      <li>
                        <a href="pricing.html">Pricing</a>
                      </li>
                      <li>
                        <a href="shop.html">Shop</a>
                      </li>
                      <li>
                        <a href="shop-details.html">Shop Details</a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-sub">
                    <a href="blog.html">Blog</a>
                    <ul>
                      <li>
                        <a href="blog.html">Blog</a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 d-none d-lg-block">
            <a href="contact.html" className="top-btn mt-10 mb-10">
              reservation{" "}
            </a>
          </div>
          <div className="col-12">
            <div className="mobile-menu mean-container">
              <div className="mean-bar">
                <a
                 
                  className="meanmenu-reveal"
                  onClick={toggleMenu}

                  style={{
                    right: 0,
                    left: "auto",
                    textAlign: "center",
                    textIndent: 0,
                    fontSize: 18
                  }}
                >
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </a>
                <nav className="mean-nav">
                  <ul style={{ display: isMenuOpen ? "block" : "none" }}>
                  <li className="has-sub">
                    <a href="/">Home</a>
                 
                  </li>
                  <li>
                    <a href="/aboutus">About</a>
                  </li>
                  <li className="has-sub">
                    <a href="/ROOMS">Accomdation</a>
                  
                  </li>
                  <li className="has-sub">
                    <a href="/Service">Services</a>
                  
                  </li>
                  <li className="has-sub">
                    <a href="/Gallery">Gallery</a>
              
                  </li>
                  <li className="has-sub">
                    <a href="/THINGDTODO">Attraction point</a>
                    
                  </li>
                  <li className="has-sub">
                    <a href="/Event">Events</a>
                    
                  </li>
                  <li>
                    <a href="/Contectus">Contact Us</a>
                  </li>

                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<header className="header-area header-three nevone">
  <div className="header-top second-header d-none d-md-block">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-10 col-md-10 d-none d-lg-block">
          <div className="header-cta">
            <ul>
              <li>
                <i className="far fa-clock" />
                <  IoMailOutline />
                <span> sales@siaresort.com/fo@siaresort.com</span>
              </li>
              <li>
                <i className="far fa-mobile" />
                <IoMdCall />
                <strong> :+91-9404100001</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 d-none d-lg-block text-right">
          <div className="header-social">
            <span>
              <a href="#" title="Facebook">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#" title="LinkedIn">
                <i className="fab fa-instagram" />
              </a>
              <a href="#" title="Twitter">
                <i className="fab fa-twitter" />
              </a>
              <a href="#" title="Twitter">
                <i className="fab fa-youtube" />
              </a>
            </span>
            {/*  /social media icon redux */}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="header-sticky" className="menu-area">
    <div className="container">
      <div className="second-menu">
        <div className="row align-items-center">
          <div className="col-xl-1 col-lg-1">
            <div className="logo">
              <a href="/">
                <img  src={logo}	 alt="logo" style={{width:"120px"}} />
              </a>
            </div>
          </div>
          <div className="col-xl-9 col-lg-10">
            <div className="main-menu text-center">
              <nav id="mobile-menu" style={{ display: "block" }}>
                <ul>
                  <li className="has-sub">
                    <a href="/">Home</a>
                 
                  </li>
                  <li>
                    <a href="/aboutus">About</a>
                  </li>
                  <li className="has-sub">
                    <a href="/ROOMS">Accomdation</a>
                  
                  </li>
                  <li className="has-sub">
                    <a href="/Service">Services</a>
                  
                  </li>
                  <li className="has-sub">
                    <a href="/Gallery">Gallery</a>
              
                  </li>
                  <li className="has-sub">
                    <a href="/THINGDTODO">Attraction point</a>
                    
                  </li>
                  <li className="has-sub">
                    <a href="/Event">Events</a>
                    
                  </li>
                  <li>
                    <a href="/Contectus">Contact Us</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 d-none d-lg-block">
            <a  href='https://asiatech.in/book_now/account/3735623638343034643062623462326531336662376630333937323663353165'  className="top-btn mt-10 mb-10" style={{color:"white"}}>
              Book Now{" "}
            </a>
          </div>
          
          <div className="col-12">
            <div className="mobile-menu" />
          </div>
        </div>
      </div>
    </div>
  </div>
</header>


<MailPop show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  )
}

export default Hadder
